import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { SDK_API_HOST } from '../../constants';
import { TCreateSessionPayload, TCreateSessionResponse } from './types';

const indexesSdkApi = createApi({
  reducerPath: 'indexes-api',
  baseQuery: fetchBaseQuery({
    baseUrl: SDK_API_HOST + '/api/v1',
  }),
  endpoints: (builder) => ({
    createSession: builder.mutation<TCreateSessionResponse, string>({
      query: (videoName) => ({
        url: '/sessions',
        method: 'POST',
        body: {
          name: videoName,
          integration_id: 1,
        } as TCreateSessionPayload,
      }),
    }),
  }),
});

export const { useCreateSessionMutation } = indexesSdkApi;
export default indexesSdkApi;
