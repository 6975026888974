import { FaceDetector, FaceDetectorEvent } from '@index5/face-detector';
import {
  FC,
  Fragment,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useCreateSessionMutation } from '../api/indexes-sdk-api';
import { SDK_API_HOST, SDK_API_TOKEN } from '../constants';
import { useAuthContext } from '../context/auth-context';
import { useIndexesContext } from '../context/indexes-context';
import { useVideoContext } from '../context/video-context';
import { IExportIndex } from '../types/index-core-types';
import { UserVideo } from './user-video';

const virtualCanvas = document.createElement('canvas');

export const IndexesService: FC<PropsWithChildren> = memo(({ children }) => {
  const { videoRef, isCameraError } = useVideoContext();

  const [indexesStarted, setIndexesStarted] = useState(false);

  const [createSession] = useCreateSessionMutation();

  const {
    personVideoRef,
    personCameraStarted,
    setStoppedByIndexes,
    stoppedByIndexes,
    setFirstIndexAdded,
    firstIndexAdded,
    viewGuarantee,
    videoWasStarted,
    setIndexData,
    indexData,
  } = useIndexesContext();

  const { linkData } = useAuthContext();

  const handleCreateSession = useCallback(async () => {
    try {
      if (!linkData) {
        return undefined;
      }

      const result = await createSession(linkData.videoSetting.title).unwrap();

      return result.id;
    } catch (e) {
      console.error(e);
    }
  }, [linkData]);

  useEffect(() => {
    if (
      personVideoRef.current &&
      personVideoRef.current.video &&
      personCameraStarted &&
      !isCameraError &&
      !indexesStarted
    ) {
      const video = personVideoRef.current.video;

      setIndexesStarted(true);

      handleCreateSession().then((sessionId) => {
        if (!sessionId || !SDK_API_HOST || !SDK_API_TOKEN) {
          throw new Error(
            "Can't start face detector, not sessionId or host or token"
          );
        }

        const detector = new FaceDetector({
          // url бекенда
          apiUrl: SDK_API_HOST,
          // токен для авторизации
          token: SDK_API_TOKEN,
          // 'client' | 'server' | 'both' если не указать, будет определен автоматически
          mode: 'client',
          // ID сессии
          sessionId,
          // Флаг отображения результатов на canvas
          drawResults: false,
          // video с камеры пользователя
          video,
          // canvas для отображения спроецированной маски
          canvas: virtualCanvas,
        });

        void detector
          .on(FaceDetectorEvent.BaseIndexReceived, (index) => {
            setIndexData({
              attention: index.attention,
              involvement: Math.round(
                0.6 * index.attention + 0.4 * (100 - index.tiredness)
              ),
              amazement: Math.round(100 - index.attention),
              tiredness: index.tiredness,
              happiness: index.emotions,
            } as IExportIndex);
          })
          .create();
      });
    }
  }, [personCameraStarted, isCameraError, indexesStarted, handleCreateSession]);

  const increaseAttentionValue = useMemo(
    () => linkData?.videoSetting.increaseAttentionValue ?? 50,
    [linkData]
  );

  useEffect(() => {
    if (videoRef.current) {
      if (
        (indexData.attention || 0) < increaseAttentionValue &&
        viewGuarantee &&
        !videoRef.current.paused
      ) {
        videoRef.current.pause();
        setStoppedByIndexes(true);
      } else if (
        stoppedByIndexes &&
        videoRef.current.paused &&
        videoWasStarted &&
        (indexData.attention || 0) >= increaseAttentionValue
      ) {
        setStoppedByIndexes(false);
        void videoRef.current.play();
      }

      if (
        indexData.attention !== undefined &&
        indexData.attention !== 0 &&
        !firstIndexAdded
      ) {
        setFirstIndexAdded(true);
      }
    }
  }, [
    indexData,
    firstIndexAdded,
    viewGuarantee,
    stoppedByIndexes,
    videoWasStarted,
    increaseAttentionValue,
  ]);

  return (
    <Fragment>
      <UserVideo ref={personVideoRef} />
      {children}
    </Fragment>
  );
});
